<template>
  <div class="right-section pt-6 is-size-7">
    <h3 class="title is-6">Contact Us</h3>
    - Phone:<br />
    <div class="ml-3">(770) 292-9389</div>
    - Sales:<br /><a href="mailto:sales@satori-assoc.com" class="ml-3"
      >sales@satori-assoc.com</a
    ><br />
    - support:<br />
    <a href="mailto:support@satori-assoc.com" class="ml-3">
      support@satori-assoc.com</a
    >
    <br /><br />
    <h3 class="title is-6">Copyright</h3>
    <div class="is-size-7 ml-3">
      1996-2024<br />
      &#169; Satori &amp; Associates, Inc.<br />
      All rights reserved
    </div>
  </div>
</template>

<script setup></script>

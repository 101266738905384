<template>
  <div id="app">
    <div id="layout">
      <div id="header">
        <div id="title">{{ this.pageName }}</div>
        <div class="mybreadcrumb">
          <router-link to="/">News</router-link> ::
          <router-link to="/product">Products</router-link> ::
          <router-link to="/about">About</router-link>
        </div>
        <img id="logo" alt="Satori & Associates, Inc." />
      </div>
      <div id="content">
        <transition name="fade">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
// import API from "@/Druware.Web.Shared/api/api";

export default {
  components: {},
  data: function () {
    return {
      pageName: "",
      crumb: {
        name: "",
        url: "",
      },
      breadcrumbs: [],
    };
  },
  methods: {
    createBreadcrumbsFromPath(path) {
      this.breadcrumbs = [];
      let _u = "";
      const _path = path.split("/");
      for (let i = 0; i < _path.length; i++) {
        _u += _path[i] + "/";
        let c = new Object();
        c.name = _path[i];
        c.url = _u;
        if (c.name == "" && this.breadcrumbs.length > 0) break;
        this.breadcrumbs.push(c);
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to == from) return;
      this.createBreadcrumbsFromPath(to.path);
      this.pageName = to.name;
      window.document.title =
        "Satori &amp; Associates - " + (this.pageName ? this.pageName : "");
      // this.getSession();
    },
  },
  mounted() {
    this.pageName = this.$router.currentRoute.name;
    if (this.$pageName != null) this.pageName = this.$pageName;
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1px) {
  #app {
    height: 100%;
    width: 100%;
    #layout {
      position: absolute;
      background: #ffffff;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      #header {
        position: absolute;
        background-image: url("img/sa-header-bg.png");
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100px;
        z-index: 2;
        #title {
          font-size: 1.7em;
          font-weight: bolder;
          color: #000000;
          padding-left: 8px;
          padding-top: 10px;
          visibility: hidden;
        }
        .mybreadcrumb {
          position: absolute;
          top: 58px;
          left: 8px;
          width: auto;
          color: #000000;
          font-size: 12pt;
          clear: none;
          a {
            color: #999999;
            text-decoration: none;
          }
          a:hover {
            color: #3c3c3c;
            text-decoration: underline;
          }
          a.router-link-exact-active {
            color: #000000;
          }
        }
        #logo {
          content: url("img/SA-WebLogo.png");
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          float: right;
          clear: none;
        }
      }
      #content {
        position: absolute;
        top: 85px;
        left: 0px;
        padding: 8px;
        width: 100%;
        height: calc(100% - 64px);
        overflow-y: auto;
        background-color: #fcfcfc;
      }
    }
  }
}

@media screen and (min-width: 416px) {
  #app {
    #layout {
      #header {
        #logo {
          content: url("img/SA-WebLogo.png");
          position: absolute;
          left: calc(100% - 400px);
          max-width: 400px;
          max-height: 100px;
          float: right;
          clear: none;
        }
      }
      #content {
        position: absolute;
        top: 85px;
        left: 0px;
        padding: 8px;
        width: 100%;
        height: calc(100% - 100px);
        overflow-y: auto;
        background-color: #fcfcfc;
      }
    }
  }
}

@media screen and (min-width: 767px) {
  #app {
    #layout {
      #header {
        #title {
          visibility: visible;
        }
      }
    }
  }
}
</style>

<script setup></script>

<template>
  <div class="component-article-summary pb-2">
    <article class="">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-64x64">
            <img :src="iconForType(article)" alt="Image" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title is-5">
              <router-link
                class="is-dark"
                :to="'/article/' + article.permalink"
              >
                {{ article.title }}</router-link
              >
            </div>
            <span v-html="compileMarkdown(article.summary)"></span>
          </div>
          <div class="level is-mobile">
            <div class="level-left">
              <small class="level-item">
                {{ article.byLine != "," ? article.byLine : "System" }}
                @ {{ formatDate(article.posted) }}
              </small>
            </div>
            <div class="level-right">
              <!--b-taglist v-if="article != null" class="level-item">
                <b-tag
                  type="is-light"
                  v-for="item in article.tags"
                  :key="article.tags.indexOf(item)"
                  >{{ item }}</b-tag
                >
              </b-taglist-->

              <router-link
                :to="'/article/' + article.permalink"
                class="level-item left"
                aria-label="view article"
              >
                [ see more ]
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import Marked from "marked";

export default {
  name: "component-article-summary",
  props: {
    article: Object,
    session: Object,
  },
  methods: {
    formatDate(str) {
      var d = new Date(str);
      return (
        d.toLocaleDateString("en-US") + "  " + d.toLocaleTimeString("en-US")
      );
    },
    compileMarkdown(str) {
      return Marked(str);
    },
    iconForType(article) {
      return article.type == null
        ? "/icon/article.gif"
        : "/api/asset/" + article.type.icon.fileName;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title a {
  color: #000000;
}

@media screen and (min-width: 1px) {
  .component-article-summary {
    article {
      margin-bottom: 8px;
    }

    .media-content {
      font-size: 0.9em;
    }
  }
}
@media screen and (min-width: 767px) {
  .component-article-summary {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>

<template>
  <div class="about">
    <div class="view-content">
      <!-- begin content region -->
      <div class="title has-text is-4 page-name">News</div>
      <br />

      <div v-for="item in list" :key="list.indexOf(item)">
        <article-summary :article="item"></article-summary>
      </div>

      <bottom-section></bottom-section>
    </div>
    <right-section></right-section>
  </div>
</template>
<script>
import API from "@/api";
import ArticleSummary from "@/components/ArticleSummary";
import RightSection from "@/components/RightSection";
import BottomSection from "@/components/BottomSection.vue";

export default {
  name: "news-view",
  components: {
    BottomSection,
    ArticleSummary,
    RightSection,
  },
  data: function () {
    return {
      list: [],
      isLoading: false,
      currentPage: 0,
    };
  },
  methods: {
    showMessage(msg, type = "is-danger") {
      /* this.$oruga.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type,
      });*/
      console.log(msg + " of " + type);
    },
    getList() {
      this.isLoading = true;
      API.list(
        API.url.news,
        this,
        function (obj, data) {
          obj.list = data.list;
          obj.isLoading = false;
        },
        function (obj, err) {
          obj.showMessage(err.message);
          obj.isLoading = false;
        },
        this.currentPage,
        10
      );
    },
  },
  mounted() {
    this.isLoading = true;
    this.getList();
  },
};
</script>

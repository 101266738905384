import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from "@oruga-ui/theme-bulma";

import "@oruga-ui/theme-bulma/dist/bulma.css";
require("@/assets/main.scss");

createApp(App).use(Oruga, bulmaConfig).use(router).mount("#app");

<template>
  <div class="bottom-section section is-size-7 pt-4">
    <div class="columns is-mobile">
      <div class="column">
        <h3 class="title is-6">Contact Us</h3>
        - Phone:<br />
        <div class="ml-3">(770) 292-9389</div>
        - Sales:<br /><a href="mailto:sales@satori-assoc.com" class="ml-3"
          >sales@satori-assoc.com</a
        ><br />
        - support:<br />
        <a href="mailto:support@satori-assoc.com" class="ml-3">
          support@satori-assoc.com</a
        >
      </div>
      <div class="column">
        <h3 class="title is-6">Copyright</h3>
        <div class="is-size-7 ml-3">
          1996-2024<br />
          &#169; Satori &amp; Associates, Inc.<br />
          All rights reserved
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.bottom-section {
  border-top: #999999 1px solid;
}
</style>
